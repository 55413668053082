<template>
  <header
    id="header_section"
    class="header_section sticky_header d-flex align-items-center text-white clearfix"
    :class="classList"
    >
    <div class="container">
      <div class="row align-items-center">

        <div class="col-lg-2">
          <div class="brand_logo">
            <router-link :to="{ name: 'Home'}" class="brand_link">
              <img src="../../assets/images/logo/logo_whitewhite.png" alt="Excez Solution">
              <img src="../../assets/images/logo/logo.png" alt="Excez Solution">
            </router-link>
            <button type="button" class="menu_btn">
              <i class="fal fa-bars"></i>
            </button>
          </div>
        </div>

        <div class="col-lg-8">
          <nav class="main_menu ul_li_right clearfix">
            <ul class="clearfix">
              <li>
                <router-link :to="{ name: 'Home' }">
                  Home
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'About' }">
                  Over ons
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Portfolio' }">
                  Ons werk
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Contact' }">
                  Contact
                </router-link>
              </li>
            </ul>
          </nav>
        </div>

        <div class="col-lg-2">
          <router-link
            :to="{ name: 'Login' }"
            class="btn btn_border border_white float-right">Inloggen
          </router-link>
        </div>

      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Menu',
  computed: {
    classList() {
      if (this.$route.name !== '404') {
        return 'border_bottom';
      }
      return '';
    },
  },
};
</script>
