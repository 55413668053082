<template>
  <section id="feature_section" class="feature_section sec_ptb_120 deco_wrap clearfix">
    <div class="container">

      <div class="feature_item">
        <div class="row align-items-center justify-content-lg-between
          justify-content-md-center justify-content-sm-center">
          <div class="col-lg-4 col-md-7 col-sm-9">
            <div class="feature_image_14 scene_1">
              <div class="big_image">
                <div class="layer" data-depth="0.2">
                  <div data-aos="fade-up" data-aos-delay="100">
                    <img
                      src="../../assets/images/features/img_33.png"
                      alt="Image" data-parallax='{"y" : 30}'>
                  </div>
                </div>
              </div>
              <div class="child_image">
                <div class="layer" data-depth="0.5">
                  <div data-aos="fade-up" data-aos-delay="200">
                    <img
                      src="../../assets/images/features/img_34.png"
                      alt="Image" data-parallax='{"y" : 60}'>
                  </div>
                </div>
              </div>
              <div class="deco_image dot_image_1">
                <div class="layer" data-depth="0.3">
                  <div data-aos="fade-up" data-aos-delay="300">
                    <img
                      src="../../assets/images/shapes/shape_25.png"
                      alt="Image" data-parallax='{"y" : 70}'>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-md-7 col-sm-9">
            <div class="section_title decrease_size ml--30 c_slide_in">
              <h2 class="title_text mb-30 c_slide_in_wrap1">
                <span class="c_slide_in_wrap2">
                  <span class="c_slide_in_wrap3">
                    Ons development proces
                  </span>
                </span>
              </h2>
              <p class="mb-50" data-aos="fade-up" data-aos-delay="200">
                Bij ieder development traject kiezen we een uniforme aanpak.
                Een belangrijk onderdeel van deze aanpak is het OTAP-proces.
                Hierbij gaat de ontwikkeling van een website of app altijd volgens
                de volgende stappen:
              </p>

              <div class="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay="300">
                <div class="item_icon icon_purple">
                  <i class="fal fa-code"></i>
                </div>
                <div class="item_content">
                  <h3 class="item_title">Ontwikkelen</h3>
                  <p class="mb-0">
                    we maken de eerste vertaalslag van design & idee
                    naar techniek op een eigen ontwikkel-omgeving
                  </p>
                </div>
              </div>

              <div class="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay="400">
                <div class="item_icon icon_yellow">
                  <i class="fal fa-bug"></i>
                </div>
                <div class="item_content">
                  <h3 class="item_title">Testen</h3>
                  <p class="mb-0">
                    we bouwen een omgeving waarin we kunnen proefdraaien en waarin de
                    opdrachtgever de eerste versie kan testen en feedback kan geven
                  </p>
                </div>
              </div>

              <div class="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay="300">
                <div class="item_icon icon_blue">
                  <i class="fal fa-badge-check"></i>
                </div>
                <div class="item_content">
                  <h3 class="item_title">Acceptatie</h3>
                  <p class="mb-0">
                    nadat de testfase is afgerond, gaan we door naar de acceptatieomgeving.
                    Hier verwerken we de laatste kleine wijzigingen. De definitieve teksten
                    worden geplaatst en alles nagelopen voor livegang
                  </p>
                </div>
              </div>

              <div class="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay="300">
                <div class="item_icon icon_green">
                  <i class="fal fa-rocket"></i>
                </div>
                <div class="item_content">
                  <h3 class="item_title">Productie</h3>
                  <p class="mb-0">
                    als alle seinen op groen staan, verhuizen we de acceptatieomgeving naar de
                    productie. Klaar voor live!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="feature_item">
        <div class="row align-items-center justify-content-lg-between
        justify-content-md-center justify-content-sm-center">
          <div class="tab-content position-relative col-lg-4 col-md-7 col-sm-9 order-last">
            <div id="tab_one" class="feature_image_3 active scene">
              <div class="big_image">
                <div class="layer" data-depth="0.2">
                  <div data-aos="fade-up" data-aos-delay="100">
                    <img
                      src="../../assets/images/features/img_7.png"
                      alt="Image" data-parallax='{"y" : 30}'>
                  </div>
                </div>
              </div>
              <div class="image_2">
                <div class="layer" data-depth="0.3">
                  <div data-aos="fade-up" data-aos-delay="200">
                    <img
                      src="../../assets/images/features/img_37.png"
                      alt="Image" data-parallax='{"y" : 50}'>
                  </div>
                </div>
              </div>
            </div>

            <div id="tab_two" class="feature_image_3 scene_2">
              <div class="big_image">
                <div class="layer" data-depth="0.2">
                  <div data-aos="fade-up" data-aos-delay="100">
                    <img
                      src="../../assets/images/features/img_7.png"
                      alt="Image" data-parallax='{"y" : 30}'>
                  </div>
                </div>
              </div>
              <div class="image_1">
                <div class="layer" data-depth="0.3">
                  <div data-aos="fade-up" data-aos-delay="200">
                    <img
                      src="../../assets/images/features/img_38.png"
                      alt="Image" data-parallax='{"y" : 50}'>
                  </div>
                </div>
              </div>
            </div>

            <div id="tab_three" class="feature_image_3 scene_3">
              <div class="big_image">
                <div class="layer" data-depth="0.2">
                  <div data-aos="fade-up" data-aos-delay="100">
                    <img
                      src="../../assets/images/features/img_7.png"
                      alt="Image" data-parallax='{"y" : 30}'>
                  </div>
                </div>
              </div>
              <div class="image_1">
                <div class="layer" data-depth="0.3">
                  <div data-aos="fade-up" data-aos-delay="200">
                    <img
                      src="../../assets/images/features/img_8.png"
                      alt="Image" data-parallax='{"y" : 50}'>
                  </div>
                </div>
              </div>
            </div>

            <div id="tab_four" class="feature_image_3 scene_4">
              <div class="big_image">
                <div class="layer" data-depth="0.2">
                  <div data-aos="fade-up" data-aos-delay="100">
                    <img
                      src="../../assets/images/features/img_39.png"
                      alt="image_not_found" data-parallax='{"y" : 30}'>
                  </div>
                </div>
              </div>
              <div class="image_3">
                <div class="layer" data-depth="0.3">
                  <div data-aos="fade-up" data-aos-delay="200">
                    <img
                      src="../../assets/images/features/img_40.png"
                      alt="image_not_found" data-parallax='{"y" : 50}'>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-md-7 col-sm-9">
            <div class="section_title increase_size mr--30 c_slide_in">
              <h2 class="title_text mb-30 text_effect_wrap c_slide_in_wrap1">
                <span class="c_slide_in_wrap2">
                  <span class="c_slide_in_wrap3">
                    Kwaliteit door de nieuwste technieken
                  </span>
                </span>
              </h2>
              <p class="mb-30" data-aos="fade-up" data-aos-delay="200">
                We maken gebruik van de nieuwste technieken en open-source frameworks.
                Hiermee garanderen onze gecertificeerde developers voor de kwaliteit!
              </p>
              <div class="tabs_nav ul_li" data-aos="fade-up" data-aos-delay="300">
                <ul class="nav" role="tablist">
                  <li>
                    <a class="active" data-toggle="tab" href="#laravel" data-tab="tab_one">
                      Laravel
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#vuejs" data-tab="tab_two">
                      VueJS
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#tailwind" data-tab="tab_three">
                      Tailwind
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#aws" data-tab="tab_four">
                      AWS
                    </a>
                  </li>
                </ul>
              </div>

              <div class="tab-content mb-30" data-aos="fade-up" data-aos-delay="400">
                <div id="laravel" class="tab-pane active">
                  <p class="mb-0">
                    Laravel legt geen beperkingen op aan de programmeur noch de wensen van de klant.
                    Het is een veilig stabiele omgeving waarmee elke applicatie ontwikkeld
                    kan worden. Het Laravel framework biedt de beste basis voor een goede snelle en
                    veilige applicatiedie al binnen enkele weken op maat door ons ontwikkeld
                    kan worden.
                  </p>
                </div>
                <div id="vuejs" class="tab-pane fade">
                  <p class="mb-0">
                    Vue.js is ideaal voor de ontwikkeling van een Single Page Application,
                    simpelweg ook wel SPA genoemd. Dit betekend dat de applicatie niet elke keer in
                    zijn volledigheid hoeft te herladen maar op een efficiënte manier
                    alleen de veranderde data ophaalt.
                  </p>
                </div>
                <div id="tailwind" class="tab-pane fade">
                  <p class="mb-0">
                    Tailwind kan gebruikt worden om een website of applicatie te voorzien van een
                    look-en-feel. Door dit framework ziet het geheel er direct fris en goed uit.
                  </p>
                </div>
                <div id="aws" class="tab-pane fade">
                  <p class="mb-0">
                    Amazon Web Services (AWS) is de cloudservice van Amazon.
                    AWS biedt on-demand cloudplatforms en API’s aan particulieren, bedrijven en
                    overheden. Feitelijk een enorme verzameling aan flexibele IT-services die via
                    het internet beschikbaar wordt gesteld.
                  </p>
                </div>
              </div>

              <router-link
                :to="{ name: 'Portfolio' }"
                class="btn bg_default_blue" data-aos="fade-up" data-aos-delay="500">
                Bekijk onze creaties
              </router-link>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Init from '@/assets/js/app';

export default {
  name: 'Features',
  mounted() {
    const buttons = document.querySelectorAll('.feature_section .tabs_nav > ul > li > a');
    buttons.forEach((elem) => {
      const button = elem;
      button.addEventListener('click', () => {
        const tabId = button.getAttribute('data-tab');
        document.querySelectorAll('.feature_image_3.active')[0].classList.remove('active');
        document.getElementById(tabId).classList.add('active');
      });
    });

    const scenes = document.querySelectorAll('.scene, .scene_1, .scene_2, .scene_3, .scene_4');
    scenes.forEach((scene) => {
      Init.parallax(scene);
    });
    Init.wrapTextEffect();
  },
};
</script>
