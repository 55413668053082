<template>
  <footer
    id="footer_section"
    :class="classes"
    class="footer_section bg_dark_blue deco_wrap pb-0 clearfix"
  >
    <div class="footer_bottom">
      <div class="container">
        <div class="row align-items-center">

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="copyright_text">
              <p class="mb-0">
                Copyright © {{ year }}
                <router-link :to="{ name: 'Home' }" class="author_link">
                  Excez Solutions
                </router-link>
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="social_icon ul_li_center"></div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="footer_menu ul_li_right">
              <ul class="clearfix">
                <li>
                  <router-link :to="{ name: 'Privacy' }">Privacy beleid</router-link>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="deco_image spahe_1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
      <img src="../../assets/images/shapes/shape_2.png" alt="Shape">
    </div>

  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    classes() {
      if (this.$route.name === 'Home') {
        return 'sec_ptb_120';
      }

      return '';
    },
  },
};
</script>
