<template>
  <section
    id="error_section"
    class="error_section bg_gradient_blue deco_wrap d-flex align-items-center text-white clearfix"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="error_content text-center">
            <h1 class="page_title">404</h1>
            <h3 class="title_text">Oeps! Pagina niet gevonden</h3>
            <p>
              Het spijt ons, de pagina waarnaar u zocht bestaat niet in onze database!<br>
              Ga misschien naar onze homepage of gebruik het menu.
            </p>
            <router-link :to="{ name: 'Home' }" class="btn bg_white">
              Terug naar de startpagina
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="deco_image spahe_1" data-aos="fade-down" data-aos-delay="100">
      <img src="../assets/images/shapes/shape_1.png" alt="spahe_not_found">
    </div>
    <div class="deco_image spahe_2" data-aos="fade-up" data-aos-delay="300">
      <img src="../assets/images/shapes/shape_2.png" alt="spahe_not_found">
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
