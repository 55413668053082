<template>
  <div id="map_section" class="map_section clearfix">
    <div id="google-map" class="google-map">
      <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=nl&amp;q=Hooimate%2019,%20Brummen+(Excez%20Solutions)&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.mapsdirections.info/nl/cirkel-straal-kaart/">Meet cirkel op kaart</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Map',
};
</script>>
