<template>
  <section id="newsletter_section" class="newsletter_section text-white clearfix">
    <div class="container">
      <div
        class="newsletter_boxed p-60 bg_gradient_blue deco_wrap"
        data-aos="fade-up" data-aos-delay="100"
      >
        <div class="row align-items-center justify-content-lg-between justify-content-md-center">
          <div class="col-lg-5 col-md-7">
            <div class="section_title decrease_size">
              <h2 class="title_text mb-15">Nieuwsbrief</h2>
              <p class="mb-0">
                Meld je aan voor onze nieuwsbrief
                <span class="d-block">en ontvang al het nieuws rondom Excez Solutions.</span>
              </p>
            </div>
          </div>

          <div class="col text-right" v-if="submitted">
            <span class="text-white">U bent aangemeld voor onze nieuwsbrief</span>
          </div>

          <div class="col-lg-4 col-md-7" v-if="! submitted">
            <form action="#">
              <div class="form_item mb-0">
                <input class="text-left" type="email" name="email" placeholder="E-mailadres">
              </div>
            </form>
          </div>

          <div class="col-lg-2 col-md-12" v-if="! submitted">
            <button
                @click="submit"
                :class="{ disabled: busy }"
                :disabled="busy"
                type="submit"
                class="btn btn_border border_white float-right"
            >
              <span v-if="busy">
                <i class="fal fa-spin fa-circle-notch"></i>
                Aanmelden
              </span>
              <span v-else>Aanmelden</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Newsletter',
  data() {
    return {
      busy: false,
      submitted: false,
    };
  },
  methods: {
    submit() {
      this.busy = true;
      setTimeout(() => {
        this.submitted = 1;
        this.busy = false;
      }, 1000);
    },
  },
};
</script>
