import Vue from 'vue';
import $ from 'jquery';
import Init from '@/assets/js/app';
import App from './App.vue';
import router from './router';

import './assets/scss/style.scss';

window.jQuery = $; window.$ = $;

Vue.config.productionTip = false;

// Bind jQuery and popper to window
window.Popper = require('popper.js').default;

require('bootstrap');

window.onload = () => {
  Init.init();
};

const script = document.createElement('script');
script.type = 'text/javascript';
script.src = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js';
document.getElementsByTagName('head')[0].appendChild(script);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
