<template>
  <main>
    <MainBanner />
    <IntroBlocks />
    <Reviews />
    <Features />
<!--    <Services />-->
    <Logos :logos="logos"/>
    <Newsletter />
  </main>
</template>

<script>
import MainBanner from '@/components/home/MainBanner.vue';
import IntroBlocks from '@/components/home/IntroBlocks.vue';
import Reviews from '@/components/home/Reviews.vue';
// import Services from '@/components/home/Services.vue';
import Logos from '@/components/Logos.vue';
import Newsletter from '@/components/Newsletter.vue';
import Features from '@/components/home/Features.vue';

export default {
  name: 'Home',
  components: {
    Features,
    Newsletter,
    Logos,
    // Services,
    Reviews,
    MainBanner,
    IntroBlocks,
  },
  data: () => ({
    logos: [
      {
        title: 'Laravel',
        src: '/images/logos/laravel.png',
      },
      {
        title: 'VueJS',
        src: '/images/logos/vue.png',
      },
      {
        title: 'Amazon Web Service',
        src: '/images/logos/aws.png',
      },
      {
        title: 'Tailwind CSS',
        src: '/images/logos/tailwind.png',
      },
      {
        title: 'Github',
        src: '/images/logos/github.png',
      },
      {
        title: 'Docker',
        src: '/images/logos/docker.png',
      },
      {
        title: 'Wordpress',
        src: '/images/logos/wordpress.png',
      },
      {
        title: 'Visual Composer',
        src: '/images/logos/vc.png',
      },
    ],
  }),
};
</script>
