<template>
  <div>
    <Banner
      title="Ons werk"
      description="Het beste van Excez Solutions"
    />

    <section id="gallery_section" class="gallery_section sec_ptb_120 clearfix">
      <div>
        <div
          class="button-group filter-btns-group ul_li_center mb-50"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <ul class="clearfix">
            <li v-for="(category, index) in categories" :key="index">
              <button
                :class="{ active: category.active }"
                :data-filter="`${category.filter}`"
                v-html="category.title"
                @click="setCategory(category)"
              ></button>
            </li>
          </ul>
        </div>

        <isotope
          ref="isotope"
          :options="options"
          itemSelector="element_grid"
          @filter="filterOption=arguments[0]"
          @sort="sortOption=arguments[0]"
          :list="list"
          v-images-loaded:on.progress="layout"
          class="zoom-gallery element_grid masonry_portfolio column_3"
        >
          <div
            v-for="(element, index) in list"
            :key="index"
            data-category="design"
            class="element-item"
          >
            <div class="casestudy_fullimage" data-aos="fade-up" data-aos-delay="300">
              <a
                :href="element.url" target="_blank"
                class="item_image popup_image"
              >
                <img :src="element.image">
              </a>
              <div class="item_content">
                <h3 class="item_title mb-0">
                  <a
                    class="popup_image"
                    :href="element.url" target="_blank"
                  >
                    {{ element.title }}
                  </a>
                </h3>
                <div class="item_category" v-for="(category, key) in element.category" :key="key">
                  <a @click="setCategory(category)">
                    {{ category }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </isotope>
      </div>
    </section>
  </div>
</template>

<script>
import Banner from '@/views/components/Banner.vue';
import isotope from 'vueisotope';
import imagesLoaded from 'vue-images-loaded';

export default {
  name: 'Portfolio',
  components: { Banner, isotope },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      categories: [
        {
          title: 'Alle projecten',
          filter: '*',
          active: true,
        },
        {
          title: '<i class="fab fa-laravel"></i> Laravel',
          filter: 'laravel',
          active: false,
        },
        {
          title: '<i class="fab fa-vuejs"></i> VueJS',
          filter: 'vuejs',
          active: false,
        },
        {
          title: '<i class="fab fa-wordpress"></i> Wordpress',
          filter: 'wordpress',
          active: false,
        },
      ],
      options: {
        itemSelector: '.element-item',
        layoutMode: 'masonry',
        animationOptions: {
          duration: 1000,
        },
        transitionDuration: '0.5s',
        masonry: {},
        getFilterData: {
          filterByCategory: (itemElem) => {
            const activeCategory = this.activeCategory.filter.toLowerCase();
            if (activeCategory === '*') {
              return true;
            }

            let itemCategories = itemElem.category;
            if (itemCategories instanceof String) {
              itemCategories = [itemCategories];
            }

            return itemCategories.indexOf(activeCategory) > -1;
          },
        },
      },
      list: [],
    };
  },
  mounted() {
    this.fetch()
      .then((items) => {
        this.list = items;
      });
  },
  methods: {
    async fetch() {
      const response = await fetch('/portfolio.json');
      return response.json();
    },
    layout() {
      this.$refs.isotope.layout('masonry');
    },
    setCategory(activeCategory) {
      if (activeCategory instanceof String) {
        // eslint-disable-next-line no-param-reassign
        activeCategory = { filter: activeCategory };
      }

      this.categories.forEach((category) => {
        // eslint-disable-next-line no-param-reassign
        category.active = (category.filter === activeCategory.filter);
        if (category.active) {
          // this.$refs.isotope.filter(category.filter);
          this.$refs.isotope.filter('filterByCategory');
        }
      });
    },
  },
  computed: {
    activeCategory() {
      return this.categories.find((category) => category.active);
    },
  },
};
</script>
