import Vue from 'vue';
import VueRouter from 'vue-router';
import VueBodyClass from 'vue-body-class';
import NotFound from '@/views/NotFound.vue';
import Portfolio from '@/views/Portfolio.vue';
import About from '@/views/About.vue';
import Contact from '@/views/Contact.vue';
import Privacy from '@/views/Privacy.vue';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Innovatieve Software ontwikkeling - Excez Solutions',
      bodyClass: 'home_software',
      metaTags: [],
    },
  },
  {
    path: '/over-ons',
    name: 'About',
    component: About,
    meta: {
      title: 'Over Excez Solutions',
      metaTags: [],
    },
  },
  {
    path: '/ons-werk',
    name: 'Portfolio',
    component: Portfolio,
    meta: {
      title: 'Het beste van Excez Solutions',
      metaTags: [],
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'Contact het team van Excez Solutions',
      metaTags: [],
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      title: 'Het privacy beleid van Excez Solutions',
      metaTags: [],
    },
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: {
      title: 'Oeps, Pagina niet gevonden - Excez Solutions',
      metaTags: [],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    // Track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  vueBodyClass.guard(to, next);

  return true;
});

export default router;
