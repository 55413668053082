<template>
  <div>
    <Banner
      title="Privacy beleid"
    />

    <section id="service_section" class="service_section sec_ptb_120 clearfix">
      <div class="container">

        <div class="section_title text-center mb-50" data-aos="fade-up" data-aos-delay="300">
          <h3 class="sub_title mb-15">Excez Solutions</h3>
          <h2 class="title_text mb-0">Waarom kiezen voor ons?</h2>
        </div>

        <div>
          <p>ExcezDesign, gevestigd aan Hooimate 19
          6971PB Brummen, is verantwoordelijk voor de verwerking van persoonsgegevens zoals
          weergegeven in deze privacyverklaring.</p>

          <h3>Contactgegevens:</h3>
          <p>https://www.excez.com<br>
          Hooimate 19<br>
          6971PB Brummen<br>
            +316 4660 7284</p>

          <h3>Persoonsgegevens die wij verwerken</h3>
          <p>ExcezDesign verwerkt je persoonsgegevens doordat je gebruik maakt van onze
            diensten en/of omdat je deze gegevens zelf aan ons verstrekt.</p>

          <p>Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:</p>
          <ul>
            <li>E-mailadres</li>
            <li>IP-adres</li>
            <li>Gegevens over jouw activiteiten op onze website</li>
            <li>Internetbrowser en apparaat type</li>
          </ul>

          <h3>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>
          Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over
          websitebezoekers die jonger zijn dan 16 jaar.
          Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of
          een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de
          online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen
          verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij
          zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige,
          neem dan contact met ons op via info@excez.com, dan verwijderen wij deze informatie.

          <h3>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h3>
          ExcezDesign verwerkt jouw persoonsgegevens voor de volgende doelen:
          - Verzenden van onze nieuwsbrief en/of reclamefolder
          - ExcezDesign analyseert jouw gedrag op de website om daarmee de website te
          verbeteren en het aanbod van producten en diensten af te stemmen op jouw voorkeuren.

          <h3>Geautomatiseerde besluitvorming</h3>
          ExcezDesign neemt #responsibility op basis van geautomatiseerde verwerkingen besluiten
          over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om
          besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een
          mens (bijvoorbeeld een medewerker van ExcezDesign) tussen zit.

          <h3>Delen van persoonsgegevens met derden</h3>

          <p>ExcezDesign verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken
          indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen
          aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze
          opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van
          beveiliging en vertrouwelijkheid van jouw gegevens. ExcezDesign blijft verantwoordelijk
          voor deze verwerkingen.</p>

          <h3>Cookies, of vergelijkbare technieken, die wij gebruiken</h3>
          <p>ExcezDesign gebruikt alleen technische en functionele cookies. En analytische cookies
          die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het
          eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone.
          De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website
          en jouw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden
          bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website
          optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te
          stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die
          eerder is opgeslagen via de instellingen van je browser verwijderen.</p>

          <h3>Gegevens inzien, aanpassen of verwijderen</h3>
          Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen.
          Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te
          trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door ExcezDesign
          en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek
          kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar
          jou of een ander, door jou genoemde organisatie, te sturen.

          <p>Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je
          persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking
          van jouw persoonsgegevens sturen naar info@excez.com.</p>

          <p>Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou
            een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je
          pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort),
          paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy.
            We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.</p>

          <p>ExcezDesign wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te
          dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de
          volgende link:
          https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
          </p>

          <h3>Hoe wij persoonsgegevens beveiligen</h3>
          <p>ExcezDesign neemt de bescherming van jouw gegevens serieus en neemt passende
          maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en
          ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch
          niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met
          onze klantenservice of via info@excez.com</p>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Banner from '@/views/components/Banner.vue';

export default {
  name: 'Privacy',
  components: { Banner },
};
</script>
