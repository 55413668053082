<template>
  <section id="partner_section" class="partner_section text-center sec_ptb_120 clearfix">
    <div class="container">

      <div class="row justify-content-center mb-30">
        <div class="col-lg-10 col-md-9 col-sm-10">
          <div class="section_title c_slide_in">
            <h2 class="title_text mb-0 c_slide_in_wrap1">
              <span class="c_slide_in_wrap2">
                <span class="c_slide_in_wrap3">
                  {{ title }}
                </span>
              </span>
            </h2>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="300">
        <div class="partner_logo col-lg-2" v-for="(logo, index) in logos" :key="index">
          <a href="#!">
            <img :src="logo.src" :alt="logo.title">
          </a>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'Logos',
  props: {
    title: {
      type: String,
      default: 'We maken gebruik van onderstaande technieken en open-source technieken',
    },
    logos: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
