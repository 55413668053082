<template>
  <div id="app" class="body_wrap">
      <div id="thetop"></div>
      <div id="backtotop">
        <a href="#" id="scroll">
          <i class="fal fa-arrow-up"></i>
          <i class="fal fa-arrow-up"></i>
        </a>
      </div>

      <Menu></Menu>
      <Menu-mobile></Menu-mobile>

      <router-view/>

      <Footer v-if="showFooter"></Footer>
  </div>
</template>
<script>

import Menu from '@/views/components/Menu.vue';
import MenuMobile from '@/views/components/MenuMobile.vue';
import Footer from '@/views/components/Footer.vue';

export default {
  components: {
    Footer,
    MenuMobile,
    Menu,
  },
  mounted() {
    const preloader = document.getElementById('preloader');
    preloader.style.display = 'none';
  },
  computed: {
    showFooter() {
      return this.$route.name !== '404';
    },
  },
};
</script>
