<template>
  <div>
    <Banner
      title="Over ons"
      description="Achter de schermen van Excez Solutions"
    />

    <section id="service_section" class="service_section sec_ptb_120 clearfix">
      <div class="container">

        <div class="section_title text-center mb-50" data-aos="fade-up" data-aos-delay="300">
          <h3 class="sub_title mb-15">Excez Solutions</h3>
          <h2 class="title_text mb-0">Waarom kiezen voor ons?</h2>
        </div>

        <div class="row mb-80">
          <div class="col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-delay="400">
            <div class="service_boxed_1">
              <div class="icon_wrap">
                <div class="item_icon bg_gradient_blue">
                  <i class="fal fa-puzzle-piece"></i>
                </div>
                <span class="icon_deco icon_deco_1">
                  <img src="../assets/images/shapes/shape_3.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_2">
                  <img src="../assets/images/shapes/shape_4.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_3">
                  <img src="../assets/images/shapes/shape_5.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_4">
                  <img src="../assets/images/shapes/shape_6.png" alt="Shape">
                </span>
              </div>
              <h3 class="item_title">
                <a>Maatwerk oplossingen</a>
              </h3>
              <p class="mb-50">
                Excez Solutions is sterk in maatwerk en technische oplossingen.
                Daarmee versterken en optimaliseren wij jouw online business.
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-delay="500">
            <div class="service_boxed_1">
              <div class="icon_wrap">
                <div class="item_icon bg_gradient_green">
                  <i class="fal fa-medal"></i>
                </div>
                <span class="icon_deco icon_deco_1">
                  <img src="../assets/images/shapes/shape_3.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_2">
                  <img src="../assets/images/shapes/shape_4.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_3">
                  <img src="../assets/images/shapes/shape_5.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_4">
                  <img src="../assets/images/shapes/shape_6.png" alt="Shape">
                </span>
              </div>
              <h3 class="item_title">
                <a>Code kwaliteit</a>
              </h3>
              <p class="mb-50">
                Al onze software wordt geprogrammeerd door middel van de coding standaard PSR2.
                Hiermee garanderen we kwaliteit en is doorontwikkeling voor elke programmeur
                een simpel karwei!
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-delay="600">
            <div class="service_boxed_1">
              <div class="icon_wrap">
                <div class="item_icon bg_gradient_orange">
                  <i class="fal fa-robot"></i>
                </div>
                <span class="icon_deco icon_deco_1">
                  <img src="../assets/images/shapes/shape_3.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_2">
                  <img src="../assets/images/shapes/shape_4.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_3">
                  <img src="../assets/images/shapes/shape_5.png" alt="Shape">
                </span>
                <span class="icon_deco icon_deco_4">
                  <img src="../assets/images/shapes/shape_6.png" alt="Shape">
                </span>
              </div>
              <h3 class="item_title">
                <a>Automatisering</a>
              </h3>
              <p class="mb-50">
                Veelal van onze processen zijn geauomatiseerd. Zo kunnen we snel & veilig handelen,
                (test)omgeving creëren en updates doorvoeren.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="feature_section" class="feature_section bg_gradient_blue sec_ptb_120 clearfix">
      <div class="container">
        <div class="feature_item">
          <div class="row align-items-center
          justify-content-lg-between justify-content-md-center justify-content-sm-center">

            <div class="col-lg-6 col-md-7 col-sm-8">
              <div class="feature_image">
                <img
                  src="../assets/images/features/img_1.png"
                   alt="image_not_found" data-aos="fade-up" data-aos-delay="100">
                <div class="phone_image" data-aos="fade-up" data-aos-delay="300">
                  <img src="../assets/images/features/img_2.png" alt="image_not_found">
                </div>
                <div class="watch_image" data-aos="fade-up" data-aos-delay="500">
                  <img src="../assets/images/features/img_3.png" alt="image_not_found">
                </div>
              </div>
            </div>

            <div class="col-lg-5 col-md-6 col-sm-7">
              <div class="section_title ml--30 text-white" data-aos="fade-up" data-aos-delay="300">
                <span class="icon mb-30">
                  <i class="fal fa-box-open"></i>
                </span>
                <h2 class="title_text mb-30">
                  We houden ervan om geweldige en nuttige software te bouwen
                </h2>
                <p class="mb-30">
                  We zijn gepassioneerd in software ontwikkeling. Zo willen we de mooiste
                  applicaties op de markt brengen voor onze partners. Dit uiten we niet alleen
                  in de code maar ook in het gehele development proces!
                </p>
                <router-link :to="{ name: 'Portfolio' }" class="btn btn_border border_white">
                  Laat me inspireren
                </router-link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <Logos
      title="Een greep uit onze relaties"
      :logos="logos" />
  </div>
</template>

<script>
import Banner from '@/views/components/Banner.vue';
import Logos from '@/components/Logos.vue';

export default {
  name: 'Portfolio',
  components: { Logos, Banner },
  data() {
    return {
      logos: [
        {
          title: 'Yaabba',
          src: '/images/logos/yaabba.png',
        },
        {
          title: 'Orion Media',
          src: '/images/logos/orionmedia.png',
        },
        {
          title: 'Wux',
          src: '/images/logos/wux.png',
        },
        {
          title: 'Bugcapture',
          src: '/images/logos/bugcapture.png',
        },
        {
          title: 'Bandenbar',
          src: '/images/logos/bandenbar.png',
        },
      ],
    };
  },
  mounted() {},
};
</script>
