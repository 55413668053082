<template>
  <section
    id="breadcrumb_section"
    class="breadcrumb_section bg_gradient_blue deco_wrap d-flex
    align-items-center text-white clearfix">
    <div class="container">
      <div class="breadcrumb_content text-center" data-aos="fade-up" data-aos-delay="100">
        <h1 class="page_title">{{ title }}</h1>
        <p class="mb-0">{{ description }}</p>
      </div>
    </div>

    <div class="deco_image spahe_1" data-aos="fade-down" data-aos-delay="300">
      <img src="../../assets/images/shapes/shape_1.png" alt="Figuur 1">
    </div>
    <div class="deco_image spahe_2" data-aos="fade-up" data-aos-delay="500">
      <img src="../../assets/images/shapes/shape_2.png" alt="Figuur 2">
    </div>
  </section>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
