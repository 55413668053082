<template>
  <!-- mobile sidebar menu - start -->
  <div class="sidebar-menu-wrapper">
    <div id="mobile_menu" class="mobile_menu">

      <div class="brand_logo mb-50 clearfix">
        <a href="index.html" class="brand_link">
          <img src="assets/images/logo/logo_2.png" alt="logo_not_found">
        </a>
        <span class="close_btn"><i class="fal fa-times"></i></span>
      </div>

      <div class="about_content mb-50 clearfix">
        <h3 class="item_title">
          We giving you a shiny future to your Business
        </h3>
        <p class="mb-30">
          Why say old chap that is spiffin barney and nancy boys bleeder
        </p>
        <div class="btns_group ul_li clearfix">
          <ul class="clearfix">
            <li><a href="sign_up.html" class="btn bg_default_blue">Get Started</a></li>
            <li>
              <a href="about.html" class="btn btn_border">
                Learn More
                <i class="fal fa-long-arrow-right ml-1"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div id="menu_list" class="menu_list ul_li_block mp_balancing mb-50 clearfix">
        <ul class="clearfix">
          <li class="active menu_item_has_child">
            <a href="#home_submenu" data-toggle="collapse" aria-expanded="false">Home Pages</a>
            <ul class="submenu collapse" id="home_submenu" data-parent="#menu_list">
              <li><a href="home_agency.html">Agency</a></li>
              <li><a href="home_app_landing.html">App Landing</a></li>
              <li><a href="home_chat.html">Chat</a></li>
              <li><a href="home_digital_agency.html">Digital Agency</a></li>
              <li><a href="home_digital_marketing.html">Digital Marketing</a></li>
              <li><a href="home_hr_management.html">HR Management</a></li>
              <li><a href="home_payment.html">Payment</a></li>
              <li><a href="home_project_management.html">Project Management</a></li>
              <li><a href="home_saas.html">SAAS</a></li>
              <li class="active"><a href="home_software.html">Software</a></li>
            </ul>
          </li>

          <li class="menu_item_has_child">
            <a href="#portfolio_submenu" data-toggle="collapse" aria-expanded="false">
              Portfolio Pages
            </a>
            <ul class="submenu collapse" id="portfolio_submenu" data-parent="#menu_list">
              <li><a href="portfolio_fullimage_2_column.html">Full Image (2 Column)</a></li>
              <li><a href="portfolio_fullimage_3_column.html">Full Image (3 Column)</a></li>
              <li><a href="portfolio_fullimage_4_column.html">Full Image (4 Column)</a></li>

              <li><a href="portfolio_grid_2_column.html">Grid Style (2 Column)</a></li>
              <li><a href="portfolio_grid_3_column.html">Grid Style (3 Column)</a></li>
              <li><a href="portfolio_grid_4_column.html">Grid Style (4 Column)</a></li>

              <li><a href="portfolio_details_1.html">Portfolio Details V.1</a></li>
              <li><a href="portfolio_details_2.html">Portfolio Details V.2</a></li>
              <li><a href="portfolio_details_3.html">Portfolio Details V.3</a></li>
            </ul>
          </li>

          <li class="menu_item_has_child">
            <a href="#gallery_submenu" data-toggle="collapse" aria-expanded="false">
              Gallery Pages
            </a>
            <ul class="submenu collapse" id="gallery_submenu" data-parent="#menu_list">
              <li><a href="gallery_fullimage_2_column.html">Full Image (2 Column)</a></li>
              <li><a href="gallery_fullimage_3_column.html">Full Image (3 Column)</a></li>
              <li><a href="gallery_fullimage_4_column.html">Full Image (4 Column)</a></li>

              <li><a href="gallery_grid_2_column.html">Grid Style (2 Column)</a></li>
              <li><a href="gallery_grid_3_column.html">Grid Style (3 Column)</a></li>
              <li><a href="gallery_grid_4_column.html">Grid Style (4 Column)</a></li>

              <li><a href="gallery_details_1.html">Gallery Details V.1</a></li>
              <li><a href="gallery_details_2.html">Gallery Details V.2</a></li>
              <li><a href="gallery_details_3.html">Gallery Details V.3</a></li>
            </ul>
          </li>

          <li><a href="about.html">About Us</a></li>
          <li><a href="pricing.html">Pricing Plans</a></li>
          <li><a href="process.html">Process</a></li>

          <li class="menu_item_has_child">
            <a href="#pages_submenu" data-toggle="collapse" aria-expanded="false">Other Pages</a>
            <ul class="submenu collapse" id="pages_submenu" data-parent="#menu_list">
              <li><a href="service_1.html">Service V.1</a></li>
              <li><a href="service_2.html">Service V.2</a></li>
              <li><a href="service_3.html">Service V.3</a></li>
              <li><a href="case_study.html">Case Study</a></li>
              <li><a href="case_study_details.html">Case Study Details</a></li>
              <li><a href="faq_1.html">F.A.Q. V.1</a></li>
              <li><a href="faq_2.html">F.A.Q. V.2</a></li>
              <li><a href="job.html">Jobs</a></li>
              <li><a href="job_details.html">Job Details</a></li>
              <li><a href="job_apply.html">Job Apply</a></li>
              <li><a href="team.html">Creative Team</a></li>
              <li><a href="sign_in.html">Sign In</a></li>
              <li><a href="sign_up.html">Sign Up</a></li>
              <li><a href="404.html">404</a></li>
            </ul>
          </li>

          <li class="menu_item_has_child">
            <a href="#shop_submenu" data-toggle="collapse" aria-expanded="false">Shop</a>
            <ul class="submenu collapse" id="shop_submenu" data-parent="#menu_list">
              <li><a href="shop_grid.html">Shop Grid</a></li>
              <li><a href="shop_list.html">Shop List</a></li>
              <li><a href="shop_details.html">Shop Details</a></li>
              <li><a href="shop_cart.html">Shop Cart</a></li>
              <li><a href="shop_checkout.html">Shop Checkout</a></li>
              <li><a href="shop_wishlist.html">Shop Wishlist</a></li>
            </ul>
          </li>

          <li class="menu_item_has_child">
            <a href="#news_submenu" data-toggle="collapse" aria-expanded="false">News</a>
            <ul class="submenu collapse" id="news_submenu" data-parent="#menu_list">
              <li><a href="blog_list.html">Blog List</a></li>
              <li><a href="blog_grid_1.html">Blog Grid V.1</a></li>
              <li><a href="blog_grid_2.html">Blog Grid V.2</a></li>
              <li><a href="blog_masonry.html">Blog Masonry</a></li>
              <li><a href="blog_details.html">Blog Details</a></li>
            </ul>
          </li>

          <li><a href="contact.html">Contact Us</a></li>
        </ul>
      </div>

      <div class="contact_info ul_li_block mb-50">
        <h3 class="item_title">Contact Us</h3>
        <ul class="clearfix">
          <li><a href="#!"><span>Email:</span> makro@gmail.com</a></li>
          <li><a href="#!"><span>Phone:</span> +948 256 347 968</a></li>
        </ul>
      </div>

      <div class="social_links ul_li mb-50">
        <h3 class="item_title">Follow Us</h3>
        <ul class="clearfix">
          <li><a href="#!"><i class="icon-facebook"></i></a></li>
          <li><a href="#!"><i class="icon-twitter"></i></a></li>
          <li><a href="#!"><i class="icon-vimeo"></i></a></li>
          <li><a href="#!"><i class="icon-linkedin"></i></a></li>
        </ul>
      </div>

      <div class="app_content mb-80 clearfix">
        <h2 class="item_title">support & downloads</h2>
        <p class="mb-30">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </p>
        <div class="btns_group ul_li clearfix">
          <ul class="clearfix">
            <li>
              <a href="#" class="btn bg_default_blue">
                <i class="fab fa-apple mr-1"></i>
                App Store
              </a>
            </li>
            <li>
              <a href="#!" class="btn btn_border">
                <i class="fab fa-google-play mr-1"></i>
                Google Play
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="copyright_text text-center mb-15">
        <p class="mb-0">
          Copyright © 2020 Desing by <a href="https://droitthemes.com/" class="author_link">DroitThemes</a>
        </p>
      </div>

      <div class="footer_menu ul_li_center text-center">
        <ul class="clearfix">
          <li><a href="#!">Terms of Use</a></li>
          <li><a href="#!">Privacy Policy</a></li>
        </ul>
      </div>

    </div>
    <div class="overlay"></div>
  </div>
  <!-- mobile sidebar menu - end -->
</template>

<script>
export default {
  name: 'MenuMobile',
};
</script>
