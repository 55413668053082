<template>
  <section id="testimonial_section" class="testimonial_section sec_ptb_120 pt-0 clearfix deco_wrap">
    <div class="bg_area bg_gradient_blue sec_ptb_120 pb-0 clearfix">
      <div class="container">
        <div class="section_title increase_size text-white mb-80">
          <h2 class="title_text mb-0 c_slide_in">
            <span class="c_slide_in_wrap1">
              <span class="c_slide_in_wrap2">
                <span class="c_slide_in_wrap3">Wat klanten zeggen</span>
              </span>
            </span>
          </h2>
        </div>

        <div
          id="testimonial_carousel_2"
          class="testimonial_carousel_2 owl-carousel arrow_top_right owl-theme"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="item">
            <div class="testimonial_item">
              <div class="admin_wrap mb-30 clearfix">
                <div class="rating_star ul_li_right">
                  <ul class="clearfix">
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                  </ul>
                </div>
                <div class="thumbnail_image">
                  <img src="../../assets/images/meta/img_1.png" alt="image_not_found">
                </div>
                <div class="admin_content">
                  <h3 class="admin_name">Evi Breukers</h3>
                  <span class="admin_title">Mede-eigenaar Yaabba</span>
                </div>
              </div>
              <p class="mb-0">
                Via Via in contact gekomen met Excez Solutions. Tijdens de eerste ontmoeting
                diep onder de indruk van de kennis van Rick. Voor mij de reden om mijn platform
                bij Excez Solutions te laten ontwikkelen.
              </p>
            </div>
          </div>

          <div class="item">
            <div class="testimonial_item">
              <div class="admin_wrap mb-30 clearfix">
                <div class="rating_star ul_li_right">
                  <ul class="clearfix">
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                  </ul>
                </div>
                <div class="thumbnail_image">
                  <img src="../../assets/images/meta/img_1.png" alt="image_not_found">
                </div>
                <div class="admin_content">
                  <h3 class="admin_name">Jordy van Katwijk</h3>
                  <span class="admin_title">Eigenaar Orion Media</span>
                </div>
              </div>
              <p class="mb-0">
                Excez Solutions kent ons door en door. Mede daardoor wordt er accuraat gewerkt
                en actief meegedacht. Een goede samenwerking en heldere communicatie.<br>&nbsp;<br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="deco_image spahe_1" data-aos="fade-down" data-aos-delay="300">
      <img src="../../assets/images/shapes/shape_1.png" alt="Figuur 1">
    </div>
    <div class="deco_image spahe_2" data-aos="fade-up" data-aos-delay="500">
      <img src="../../assets/images/shapes/shape_2.png" alt="Figuur 2">
    </div>
  </section>
</template>

<script>
import 'owl.carousel2/dist/assets/owl.carousel.css';
import $ from 'jquery';
import Init from '@/assets/js/app';

export default {
  name: 'Reviews',
  mounted() {
    Init.wrapTextEffect();
    const timer = window.setInterval(() => {
      try {
        $('#testimonial_carousel_2').owlCarousel({
          nav: true,
          margin: 0,
          loop: true,
          dots: false,
          smartSpeed: 1000,
          responsive: {
            0: {
              items: 1,
            },
            880: {
              items: 2,
            },
            991: {
              items: 2,
            },
            1000: {
              items: 2,
            },
          },
        });
        window.clearInterval(timer);
        // eslint-disable-next-line no-empty
      } catch (e) { }
    }, 100);
  },
};
</script>
