import AOS from 'aos';
import Parallax from 'parallax-js';
import $ from 'jquery';

const Init = {
  init() {
    AOS.init({
      once: true,
      duration: 800,
    });

    $('.close_btn, .overlay').on('click', () => {
      $('#mobile_menu').removeClass('active');
      $('.overlay').removeClass('active');
    });

    $('.menu_btn').on('click', () => {
      $('#mobile_menu').addClass('active');
      $('.overlay').addClass('active');
    });

    $('.mobile_menu .dropdown')
      .on('show.bs.dropdown', () => {
        $(this).find('.dropdown-menu')
          .first()
          .stop(true, true)
          .slideDown(400);
      })
      .on('hide.bs.dropdown', () => {
        $(this).find('.dropdown-menu')
          .first()
          .stop(true, true)
          .slideUp(500);
      });

    window.addEventListener('resize', () => {
      this.resize();
    });

    window.addEventListener('scroll', () => {
      this.scroll();
    });
  },
  scroll() {
    this.wrapTextEffect();
    const stickyHeader = document.getElementById('header_section');
    const { scrollTop } = document.documentElement;

    if (scrollTop > 120) {
      stickyHeader.classList.add('stuck');
    } else {
      stickyHeader.classList.remove('stuck');
    }
  },
  resize() {
    this.wrapTextEffect();
  },
  parallax(scene) {
    // eslint-disable-next-line no-unused-vars
    const parallax = new Parallax(scene, {
      scalarX: 10.0,
      scalarY: 10.0,
      selector: '.layer',
    });
  },
  wrapTextEffect() {
    const textEffectWrap = document.querySelectorAll('.text_effect_wrap, .c_slide_in');

    textEffectWrap.forEach((elem) => {
      const windowHeight = window.innerHeight - 100;
      const windowTopPosition = window.pageYOffset || document.documentElement.scrollTop;
      const windowBottomPosition = (windowTopPosition + windowHeight);
      const element = elem;
      const elementHeight = this.dimension(elem);
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = element.getBoundingClientRect();
      const elementTopPosition = elemRect.top - bodyRect.top;
      const elementBottomPosition = (elementTopPosition + elementHeight);
      if ((elementBottomPosition >= windowTopPosition)
        && (elementTopPosition <= windowBottomPosition)) {
        element.classList.add('is_show', 'is_shown');
      }
    });
  },
  dimension(element) {
    let elmHeight = null;
    let elmMargin = null;
    const elm = element;
    if (document.all) {
      elmHeight = elm.currentStyle.height;
      elmMargin = `${parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10)}`;
    } else {
      elmHeight = document.defaultView.getComputedStyle(elm, '').getPropertyValue('height');
      elmMargin = `${parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) + parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10)}`;
    }
    return (parseInt(elmHeight.replace('px', ''), 10) + parseInt(elmMargin, 10));
  },
};

export default Init;
