<template>
  <section class="service_section sec_ptb_120 clearfix">
    <div class="container">

      <div class="section_title text-center mb-30 c_slide_in">
        <h3 class="sub_title mb-15 c_slide_in_wrap1">
          <span class="c_slide_in_wrap2">
            <span class="c_slide_in_wrap3">
              We are the best
            </span>
          </span>
        </h3>
        <h2 class="title_text mb-0 c_slide_in_wrap1">
          <span class="c_slide_in_wrap2">
            <span class="c_slide_in_wrap3">
              Develop to Solutions
            </span>
          </span>
        </h2>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="service_primary_2" data-aos="fade-up" data-aos-delay="300">
            <div class="item_icon icon_image">
              <img src="../../assets/images/icons/services/icon_1.png" alt="Kwaliteit">
            </div>
            <h3 class="item_title">Kwaliteit</h3>
            <p>
              We maken gebruik van de nieuwste technieken en open-source frameworks.
              Hiermee garanderen onze gecertificeerde developers voor de kwaliteit!
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="service_primary_2" data-aos="fade-up" data-aos-delay="500">
            <div class="item_icon icon_image">
              <img src="../../assets/images/icons/services/icon_3.png" alt="Betrouwbaar">
            </div>
            <h3 class="item_title">Betrouwbaar</h3>
            <p>
              Wij garanderen de beste veiligheid voor jouw applicatie.
              Alle software leveren we AVG-/GDPR- compliant op.
              Daardoor weet je zeker dat je beveiligd werkt.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="service_primary_2" data-aos="fade-up" data-aos-delay="700">
            <div class="item_icon icon_image">
              <img src="../../assets/images/icons/services/icon_2.png" alt="icon_not_found">
            </div>
            <h3 class="item_title">Gecertificeerd</h3>
            <p>
              Onze gecertificeerde developers zorgen voor opleveringen van de hoogste kwaliteit.
              Je software zit daarom altijd gegarandeerd goed in elkaar.
            </p>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'IntroBlocks',
};
</script>
