<template>
  <div>
    <Banner
      title="Contact"
      description="" />
    <section id="contact_section" class="contact_section sec_ptb_120 clearfix">
      <div class="container">
        <div class="row justify-content-lg-between
          justify-content-md-center justify-content-sm-center"
        >
          <div class="col-lg-3 col-md-8 col-sm-10">
            <div class="contact_info ul_li_block" data-aos="fade-up" data-aos-delay="300">
              <h3 class="list_title">Adres</h3>
              <ul class="clearfix">
                <li>Hooimate 19</li>
                <li>6971 PB  Brummen</li>
              </ul>
            </div>

            <div class="contact_info ul_li_block" data-aos="fade-up" data-aos-delay="400">
              <h3 class="list_title">Contact Info</h3>
              <ul class="clearfix">
                <li>
                  <span>Telefoon:</span>
                  <a style="color:#6a7c92" href="tel:+316 4660 7284">(+31) 06 4660 7284</a>
                </li>
                <li>
                  <span>E-mail:</span>
                  <a style="color:#6a7c92" href="mailto:info@excez.com">info@excez.com</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-8 col-md-8 col-sm-10">
            <div class="contact_form" data-aos="fade-up" data-aos-delay="500">
              <form action="#">

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form_item">
                      <input type="text" name="name" placeholder="Naam">
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form_item">
                      <input type="email" name="email" placeholder="E-mailadres">
                    </div>
                  </div>
                </div>

                <div class="form_item">
                  <textarea name="comment" placeholder="Bericht..."></textarea>
                </div>

                <button type="button" class="btn bg_default_blue">Verstuur</button>

              </form>
            </div>
          </div>

        </div>
      </div>
    </section>

    <Map />
  </div>
</template>

<script>
import Map from '@/components/contact/Map.vue';
import Banner from '@/views/components/Banner.vue';

export default {
  name: 'Contact',
  components: { Banner, Map },
};
</script>
