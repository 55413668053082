<template>
  <section
    id="banner_section"
    class="banner_section software_banner deco_wrap d-flex align-items-center text-white clearfix">
    <div class="container">
      <div class="row align-items-center justify-content-lg-between">

        <div class="col-lg-5 order-last">
          <div class="banner_image scene" id="scene">
            <div class="big_image">
              <div class="layer" data-depth="0.2">
                <div data-aos="fade-up" data-aos-delay="300">
                  <img src="../../assets/images/banner/10_software/img_1.png" alt="image_not_found">
                </div>
              </div>
            </div>
            <div class="child_image_1">
              <div class="layer" data-depth="0.3">
                <div data-aos="fade-up" data-aos-delay="500">
                  <img src="../../assets/images/banner/10_software/img_2.png" alt="image_not_found">
                </div>
              </div>
            </div>
            <div class="child_image_2">
              <div class="layer" data-depth="0.4">
                <div data-aos="fade-up" data-aos-delay="700">
                  <img src="../../assets/images/banner/10_software/img_3.png" alt="image_not_found">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-7">
          <div class="banner_content c_slide_in">
            <h1 class="title_text mb-30 text_effect_wrap text_effect_wrap">
              <span class="text_effect text_effect_left font-weight-light">
                Wij zijn
              </span><br>
              <span class="text_effect text_effect_left font-weight-bold">
                Excez Solutions
              </span>
            </h1>
            <p class="mb-50" data-aos="fade-up" data-aos-delay="200">
              Een development studio,
              gepassioneerd om elke dag voor iedereen een unieke creatie te maken.
            </p>
            <div class="btns_group ul_li" data-aos="fade-up" data-aos-delay="300">
              <ul class="d-flex align-items-center clearfix">
                <li>
                  <router-link :to="{ name: 'Portfolio' }" class="btn btn_border border_white">
                    Bekijk ons werk
                  </router-link>
                <li>
                  <router-link :to="{ name: 'Contact' }" class="btn_text">
                    Contact ons
                    <i class="fal fa-long-arrow-right ml-1"></i>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div
      class="deco_image bg_shape_1"
      data-background="../assets/images/banner/10_software/bg_1.png"
    ></div>
    <div
      class="deco_image bg_shape_2"
      data-background="../assets/images/banner/10_software/bg_2.png"
    ></div>
    <div
      class="deco_image bg_shape_3"
      data-background="../assets/images/banner/10_software/bg_3.png"
      data-aos="zoom-out" data-aos-delay="100"
    ></div>

    <div class="deco_image shape_1" data-aos="fade-left" data-aos-delay="500">
      <img src="../../assets/images/banner/10_software/shape_1.png" alt="shape_not_found">
    </div>
    <div class="deco_image shape_2" data-aos="fade-right" data-aos-delay="700">
      <img src="../../assets/images/banner/10_software/shape_2.png" alt="shape_not_found">
    </div>

    <div class="deco_image cloud_1" data-aos="fade-up" data-aos-delay="500">
      <img src="../../assets/images/banner/10_software/cloud_1.png" alt="shape_not_found">
    </div>
    <div class="deco_image cloud_2" data-aos="fade-up" data-aos-delay="700">
      <img src="../../assets/images/banner/10_software/cloud_1.png" alt="shape_not_found">
    </div>
  </section>
</template>

<script>
import Init from '@/assets/js/app';

export default {
  name: 'MainBanner',
  mounted() {
    const scene = document.getElementById('scene');
    Init.parallax(scene);
    Init.wrapTextEffect();
  },
};
</script>
